import React from 'react'
import Image from 'next/image';
import dynamic from 'next/dynamic';

const CommanBtn = dynamic(() => import('../Common/CommanBtn'));


function Services({services, recentServices}:any) {

  return (
    <section className="sections">
            <div className="container">
                <div className="sections_title">
                    <h2 data-aos="fade" data-aos-delay="100">{services?.title}</h2>
                    <p data-aos="fade" data-aos-delay="200">{services?.description}</p>
                </div>
            </div>
            <div className="container">
                <div className="row icon_sec">
                {
                    recentServices && recentServices.map((service:any, index:any) => ( 
                        <div key={index}  className="col-lg-6" data-aos="fade" data-aos-delay="100">
                            <div className="icon_box_hr">
                                <div className="fit_icon">
                                    {
                                        service?.ServiceCategories?.serviceIcons?.serviceImage && !service?.ServiceCategories?.serviceIcons?.serviceIcon  && (
                                            <Image className='hs_img' src={service?.ServiceCategories?.serviceIcons?.serviceImage?.sourceUrl} alt={service?.ServiceCategories?.serviceIcons?.serviceImage?.altText} width={130} height={130} />
                                        )
                                    }
                                    { 
                                    !service?.ServiceCategories?.serviceIcons?.serviceImage && service?.ServiceCategories?.serviceIcons?.serviceIcon && (
                                        <div className="hs_icon" dangerouslySetInnerHTML={{ __html: service?.ServiceCategories?.serviceIcons?.serviceIcon }}></div>
                                    )
                                    }
                                </div>
                                <div className="icon_text">
                                    <h3>{service?.name}</h3>
                                    <div className="home_service_content"  dangerouslySetInnerHTML={{ __html: service?.ServiceCategories?.serviceCategoryExcerpt }}></div>
                                    <div className="btn_holder">
                                        <CommanBtn ariaLabel={`devxcel more describe about ${service?.node?.title}`}  href={`/services/${service?.slug}`} className="sm" text='Read More' />
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                </div>
                {
                    services?.allServicesButton?.title && (
                        <div className="btn_holder text-center" data-aos="fade" data-aos-delay="100">
                            <CommanBtn href={services?.allServicesButton?.url} className="border_btn" text={services?.allServicesButton?.title} />
                        </div>
                    )
                }
            </div>
           

    </section>
  )
}

export default Services
